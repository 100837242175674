var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4ed8f6a86e2d444b47ec704e1aa88460873e616b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";

const BUILD_ENV = process.env.BUILD_ENV;
const NODE_ENV = process.env.NODE_ENV;
const environment = NODE_ENV || BUILD_ENV || "local";

const SENTRY_ENV = process.env.SENTRY_ENV || environment;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  attachStacktrace: true,
  dsn: SENTRY_DSN,
  debug: !!(environment === "development"),
  environment: SENTRY_ENV,
  maxBreadcrumbs: 100,
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
});
