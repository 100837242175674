import { NAV_HEIGHT } from "@components/ui/nav/Nav/constants";
import styled from "@emotion/styled";

export const MainContainerWrapper = styled.main`
  background-color: ${({ theme }) => theme.colors.blue.light};
  min-height: 100%;
  min-height: 100vh;
  padding: ${NAV_HEIGHT}px 0 0 0;
  position: relative;
  width: 100%;
`;
