import { ReactElement, useCallback, useState, useEffect } from "react";
import Image from "next/image";
import {
  NavBar,
  NavBarMobileBackground,
  NavBarTopNavContainer,
  NavBarToggleButtonsContainer,
  LogoLink,
  TheEnv,
  NavBarList,
  NavBarListItem,
  NavBarContent,
  NavBarHeader,
  NavBarContainer,
  NavBarListContainer,
} from "./Nav.styled";
import { getEnv, isDevelopment, isProduction } from "@utils/config";
import MenuButton from "@components/ui/buttons/MenuButton";
import CloseButton from "@components/ui/buttons/CloseButton";
import NavBarLink from "../NavBarLink";
import { useRouter } from "next/router";

export default function Nav(): ReactElement {
  // mobile menu
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleToggleMenu = useCallback(() => {
    setToggleMenu(!toggleMenu);
  }, [setToggleMenu, toggleMenu]);

  useEffect(() => {
    const $html = document.documentElement;
    if (toggleMenu) {
      $html.scrollTop = 0;
    }
    $html.style.overflow = toggleMenu ? "hidden" : "auto";

    const $body = document.body;
    $body.style.overflow = toggleMenu ? "hidden" : "auto";
  }, [toggleMenu]);

  // route change
  const router = useRouter();

  useEffect(() => {
    function handleRouteChange() {
      setToggleMenu(false);
    }

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <NavBar>
        <NavBarMobileBackground open={toggleMenu} onClick={handleToggleMenu} />

        <NavBarHeader>
          <NavBarTopNavContainer>
            <LogoLink href="/">
              <Image
                src="/images/appytown.svg"
                alt="Appytown Logo"
                height={18}
                width={143}
              />
            </LogoLink>

            <NavBarToggleButtonsContainer>
              {toggleMenu ? (
                <CloseButton iconColor="#2e849e" onClick={handleToggleMenu} />
              ) : (
                <MenuButton onClick={handleToggleMenu} />
              )}
            </NavBarToggleButtonsContainer>
          </NavBarTopNavContainer>
        </NavBarHeader>

        <NavBarContainer open={toggleMenu}>
          <NavBarContent>
            <NavBarListContainer>
              <NavBarList>
                <NavBarListItem>
                  <NavBarLink href="/">About us</NavBarLink>
                </NavBarListItem>
                <NavBarListItem>
                  <NavBarLink href="/">Our games</NavBarLink>
                </NavBarListItem>
                <NavBarListItem>
                  <NavBarLink href="/">Developers</NavBarLink>
                </NavBarListItem>
                <NavBarListItem>
                  <NavBarLink href="/">Careers</NavBarLink>
                </NavBarListItem>
                {isDevelopment() && (
                  <>
                    <NavBarListItem>
                      <NavBarLink href="/">Full app</NavBarLink>
                    </NavBarListItem>
                    <NavBarListItem>
                      <NavBarLink href="/">Public</NavBarLink>
                    </NavBarListItem>
                    <NavBarListItem>
                      <NavBarLink href="/">Post message</NavBarLink>
                    </NavBarListItem>
                  </>
                )}
              </NavBarList>
            </NavBarListContainer>
          </NavBarContent>
        </NavBarContainer>
      </NavBar>

      {!isProduction() && <TheEnv>{getEnv(true)}</TheEnv>}
    </>
  );
}
